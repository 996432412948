import dayjs from 'dayjs';

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export function maskDate(value) {
  if (!value) return 'Nenhuma data informada';
  return dayjs(value).format('DD/MM/YYYY');
}

export function dayOfTheWeek(data) {
  const dias = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

  return dias[new Date(data).addDays(1).getDay()];
}

export function maskCurrency(value) {
  if (value === null || isNaN(value) || value === 0) return '-';
  return parseFloat(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function formatFixedDateInitial(data) {
  const ano = `${data.getFullYear()}`;
  let mes = `${data.getMonth() + 1}`;

  if (mes.length < 2) mes = `0${mes}`;

  let dataI = `${ano}-${mes}-01`;

  return dataI;
}
export function formatFixedDateFinal(data) {
  const ano = `${data.getFullYear()}`;
  let mes = `${data.getMonth() + 1}`;
  let diaAtual = `${data.getDate()}`;

  if (mes.length < 2) mes = `0${mes}`;
  if (diaAtual.length < 2) diaAtual = `0${diaAtual}`;

  const diaFinal = '31';

  if (diaAtual < diaFinal) {
    const dia = '30';
    let dataF = `${ano}-${mes}-${dia}`;

    return dataF;
  } else {
    let dataF = `${ano}-${mes}-${diaFinal}`;
    return dataF;
  }
}

export function formatDateToInput(data) {
  if (!(data instanceof Date)) {
    throw new Error("O parâmetro 'data' deve ser uma instância de data JavaScript");
  }

  const ano = `${data.getFullYear()}`;
  let mes = `${data.getMonth() + 1}`;
  let dia = `${data.getDate()}`;

  if (mes.length < 2) mes = `0${mes}`;
  if (dia.length < 2) dia = `0${dia}`;

  return [ano, mes, dia].join('-');
}

export function shuffleArray(array) {
  for (let index = array.length - 1; index > 0; index -= 1) {
    const newIndex = Math.floor(Math.random() * (index + 1));
    [array[index], array[newIndex]] = [array[newIndex], array[index]];
  }
  return array;
}
