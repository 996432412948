/* Styles */
import '@/assets/scss/main.scss';
import '@mdi/font/css/materialdesignicons.css';

/* Core */
import Vue from 'vue';
import Buefy from 'buefy';

/* Vue Main Component */
import App from './App.vue';

/* Store e Router */
import acl from './router/acl';
import router from './router';
import store from './store';

/* Vue-The-Mask */
import VueTheMask from 'vue-the-mask';

Vue.use(Buefy);
Vue.use(VueTheMask);

import { maskDate, maskCurrency, dayOfTheWeek } from './helpers/helpers';

Vue.filter('maskDate', maskDate);
Vue.filter('maskCurrency', maskCurrency);
Vue.filter('dayOfTheWeek', dayOfTheWeek);

Vue.config.productionTip = false;

new Vue({
  router,
  acl,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
