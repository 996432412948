<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  height: calc(100% - 50px);
  flex-direction: column;
}
</style>
