import httpClient from './httpClient';

const USUARIO_LOGADO_KEY = 'olimpiaparques.usuarioLogado';

export default {
  autenticar(data) {
    return httpClient.post('auth/login', data);
  },
  set(data) {
    let usuarioLogado = data ? JSON.stringify(data) : {};
    localStorage.setItem(USUARIO_LOGADO_KEY, usuarioLogado);
  },
  get() {
    return JSON.parse(localStorage.getItem(USUARIO_LOGADO_KEY));
  },
  remove() {
    localStorage.removeItem(USUARIO_LOGADO_KEY);
  },
};
