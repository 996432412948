import Vue from 'vue';
import Vuex from 'vuex';

import userService from '@/services/userService';
import tokenService from '@/services/tokenService';

Vue.use(Vuex);

Date.prototype.removeDays = function(days) {
  this.setDate(this.getDate() - parseInt(days));
  return this;
};

Date.prototype.addDays = function(days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};

export default new Vuex.Store({
  state: {
    usuario: userService.get() || null,
  },
  actions: {},
  mutations: {
    setUser(state, payload) {
      const { accessToken, user } = payload;
      state.usuario = user;
      userService.set(user);
      tokenService.set(accessToken);
    },
    logout(state) {
      state.usuario = null;
      userService.remove();
      tokenService.remove();
    },
  },
  getters: {
    sidebarRoutes: state => {
      const menus = [
        {
          name: 'Relatórios',
          icon: 'clipboard-file',
          routes: [
            {
              path: '/admin/relatorio-entrega-ingressos',
              name: 'Relatório de Entrega',
            },
          ],
        },
        {
          name: 'Cadastros',
          icon: 'plus-box-multiple',
          routes: [
            {
              path: '/admin/gerenciar-orcamentos',
              name: 'Gerenciar Orçamentos',
            },
          ],
        },
      ];

      if (state.usuario?.usuarioTipo?.id === 1) {
        const menusRelatorios = menus.find(m => m.name === 'Relatórios');

        menusRelatorios.routes.push(
          {
            path: '/admin/relatorio-vendas',
            name: 'Relatório de Vendas',
          },
          {
            path: '/admin/relatorio-revendas',
            name: 'Relatório de Revendas',
          }
        );

        const menuCadastros = menus.find(m => m.name === 'Cadastros');

        menuCadastros.routes.push(
          {
            path: '/admin/gerenciar-ingressos',
            name: 'Gerenciar Ingressos',
          },
          {
            path: '/admin/gerenciar-tarifas',
            name: 'Gerenciar Tarifas',
          },
          {
            path: '/admin/gerenciar-facilidades',
            name: 'Gerenciar Facilidades',
          },
          {
            path: '/admin/gerenciar-usuarios',
            name: 'Gerenciar Usuários',
          }
        );

        menus.push({
          name: 'Financeiro',
          icon: 'bank',
          routes: [
            {
              path: '/admin/caixa',
              name: 'Caixa',
            },
          ],
        });
      }

      return menus;
    },
  },
  modules: {},
});
