const TOKEN_KEY = 'olimpiaparques.token';

export default {
  get() {
    return localStorage.getItem(TOKEN_KEY);
  },
  set(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },
  remove() {
    localStorage.removeItem(TOKEN_KEY);
  },
};
