import axios from 'axios';
import tokenService from '@/services/tokenService';

export default {
  get(route, isAuthenticated = false) {
    let options;
    if (isAuthenticated) {
      const token = tokenService.get();
      options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return axios.get(`/${route}`, options);
  },
  post(route, data, isAuthenticated = false) {
    let options;
    if (isAuthenticated) {
      const token = tokenService.get();
      options = {
        headers: {
          Authorization: `Berear ${token}`,
        },
      };
    }

    return axios.post(`/${route}`, data, options);
  },
  put(route, data, isAuthenticated = false) {
    let options;
    if (isAuthenticated) {
      const token = tokenService.get();
      options = {
        headers: {
          Authorization: `Berear ${token}`,
        },
      };
    }

    return axios.put(`/${route}`, data, options);
  },
  delete(route, isAuthenticated = false) {
    let options;
    if (isAuthenticated) {
      const token = tokenService.get();
      options = {
        headers: {
          Authorization: `Berear ${token}`,
        },
      };
    }

    return axios.delete(`/${route}`, options);
  },
};
