import Vue from 'vue';
import VueRouter from 'vue-router';
import tokenService from '@/services/tokenService';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      rule: 'publico',
    },
  },
  {
    path: '/admin',
    component: () => import('../layouts/Admin.vue'),
    children: [
      {
        path: '',
        redirect: '/admin/relatorio-entrega-ingressos',
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/gerenciar-ingressos',
        name: 'gerenciar-ingressos',
        component: () => import('../views/Cadastros/Ingresso/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/relatorio-vendas',
        name: 'relatorio-vendas',
        component: () => import('../views/Relatorios/RelatorioVendas.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/relatorio-revendas',
        name: 'relatorio-vendas',
        component: () => import('../views/Relatorios/RelatorioRevendas.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/relatorio-entrega-ingressos',
        name: 'relatorio-entrega-ingressos',
        component: () => import('../views/Relatorios/RelatorioEntregaIngressos.vue'),
        meta: {
          requiresAuth: true,
          rule: 'operador',
        },
      },
      {
        path: '/admin/gerenciar-tarifas',
        name: 'gerenciar-tarifas',
        component: () => import('../views/Cadastros/Tarifa/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/cadastrar-tarifa',
        name: 'cadastrar-tarifa',
        component: () => import('../views/Cadastros/Tarifa/Editar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/editar-tarifa/:id',
        name: 'editar-tarifa',
        component: () => import('../views/Cadastros/Tarifa/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/gerenciar-tarifa-tipo',
        name: 'gerenciar-tarifa-tipo',
        component: () => import('../views/Cadastros/Tarifa/Tipo/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/cadastrar-tarifa-tipo',
        name: 'cadastrar-tarifa-tipo',
        component: () => import('../views/Cadastros/Tarifa/Tipo/Editar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/editar-tarifa-tipo/:id',
        name: 'editar-tarifa',
        component: () => import('../views/Cadastros/Tarifa/Tipo/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },

      {
        path: '/admin/gerenciar-facilidades',
        name: 'gerenciar-facilidade',
        component: () => import('../views/Cadastros/Facilidade/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/cadastrar-facilidade',
        name: 'cadastrar-facilidade',
        component: () => import('../views/Cadastros/Facilidade/Editar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/editar-facilidade/:id',
        name: 'editar-facilidade',
        component: () => import('../views/Cadastros/Facilidade/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },

      {
        path: '/admin/gerenciar-orcamentos',
        name: 'gerenciar-orcamentos',
        component: () => import('../views/Cadastros/Orcamento/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'operador',
        },
      },
      {
        path: '/admin/cadastrar-orcamento',
        name: 'cadastrar-orcamento',
        component: () => import('../views/Cadastros/Orcamento/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'operador',
        },
      },
      {
        path: '/admin/editar-orcamento/:id',
        name: 'editar-orcamento',
        component: () => import('../views/Cadastros/Orcamento/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'operador',
        },
      },
      {
        path: '/admin/gerenciar-usuarios',
        name: 'gerenciar-usuarios',
        component: () => import('../views/Cadastros/Usuario/Gerenciar.vue'),
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/cadastrar-usuario',
        name: 'cadastrar-usuarios',
        component: () => import('../views/Cadastros/Usuario/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/editar-usuario/:id',
        name: 'editar-usuario',
        component: () => import('../views/Cadastros/Usuario/Editar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/caixa',
        name: 'caixa',
        component: () => import('../views/Cadastros/Financeiro/Caixa.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
      {
        path: '/admin/adicionar-caixa',
        name: 'adicionar',
        component: () => import('../views/Cadastros/Financeiro/Adicionar.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          rule: 'administrador',
        },
      },
    ],
  },
  {
    path: '*',
    name: 'Página não encontrada',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      rule: 'publico',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (tokenService.get()) {
      next();
      return;
    }
    next('/admin/login');
  } else {
    next();
  }
});

export default router;
