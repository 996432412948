import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from '.';
import store from '../store';

Vue.use(AclInstaller);

export default new AclCreate({
  initial: 'public',
  notfound: {
    path: '/404',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    administrador: new AclRule('administrador').generate(),
    operador: new AclRule('operador').or('administrador').generate(),
    publico: new AclRule('publico')
      .or('operador')
      .or('administrador')
      .generate(),
  },
  middleware: async acl => {
    const usuario = store.state.usuario;

    if (usuario && usuario.usuarioTipo?.id === 1) {
      acl.change('administrador');
    } else if (usuario) {
      acl.change('operador');
    } else {
      acl.change('publico');
    }
  },
});
